<template>
    <div>
        <a-drawer title="工单详情" placement="right" :closable="true" :visible="true"
            :after-visible-change="afterVisibleChange" @close="onClose" width='600px'>
            <a-tabs default-active-key="1" @change="onChange">
                <a-tab-pane key="1" tab="需求信息">
                    <h4><strong style="color: rgba(0,0,0,0.85);">工单信息</strong> </h4>
                    <div class="information" v-if="detailData.workNumber">
                        <p>工单编号</p>
                        <p>{{ detailData.workNumber }}</p>
                    </div>
                    <div class="information" v-if="detailData.workStateName">
                        <p>工单状态</p>
                        <p>{{ detailData.workStateName }}</p>
                    </div>
                    <div class="information" v-if="detailData.gmtCreate">
                        <p>工单创建时间</p>
                        <p>{{ detailData.gmtCreate }}</p>
                    </div>
                    <div class="information" v-if="detailData.demandSource">
                        <p>需求来源</p>
                        <p v-if="detailData.demandSource == 1">i站工单</p>
                        <p v-else-if="detailData.demandSource == 2">用户</p>
                        <p v-else-if="detailData.demandSource == 3">400热线</p>
                        <p v-else-if="detailData.demandSource == 4">i友圈</p>
                        <p v-else-if="detailData.demandSource == 5">孵化器工单</p>
                    </div>
                    <div class="information"
                        v-if="(detailData.demandSource == 1 || detailData.demandSource == 2) && detailData.demandTypeName">
                        <p>需求类型</p>
                        <p>{{ detailData.demandTypeName }}</p>
                    </div>
                    <div class="information" v-if="detailData.startingTime">
                        <p>工单提出时间</p>
                        <p>{{ detailData.startingTime }}</p>
                    </div>
                    <div class="information" v-if="detailData.demandDescribe">
                        <p>需求描述</p>
                        <p>{{ detailData.demandDescribe }}</p>
                    </div>
                    <div class="information"
                        v-if="(detailData.demandSource == 1 || detailData.demandSource == 2) && detailData.desiredSolution">
                        <p>期望解决方式</p>
                        <p>{{ detailData.desiredSolution }}</p>
                    </div>
                    <div class="information" v-if="detailData.fileUrlList.length">
                        <p>附件</p>
                        <div style="display: flex;flex-wrap: wrap;">
                            <div v-for="(item, index) in detailData.fileUrlList" class="imgClass" :key="index">
                                <el-image fit="cover" class="annexBoxImage" v-if="ifPicture(item)" :src="item"
                                    :preview-src-list="previewImgList">
                                </el-image>
                                <div v-else class="videobox" @click="videoPlayer(item)">
                                    <video class="videoClass" :src="item" type="video/mp4"></video>
                                    <div class="mask">
                                        <a-icon class="maskPlayer" type="play-circle" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <h4 style="margin-top: 20px;"><strong style="color: rgba(0,0,0,0.85);">客户信息</strong> </h4>
                    <div class="information" v-if="detailData.linkmanName">
                        <p>客户名称</p>
                        <p>{{ detailData.linkmanName }}</p>
                    </div>
                    <div class="information" v-if="detailData.linkmanPhone">
                        <p>客户联系方式</p>
                        <p>{{ detailData.linkmanPhone }}</p>
                    </div>
                    <div class="information" v-if="detailData.projectName">
                        <p>客户所在项目</p>
                        <p>{{ detailData.projectName }}</p>
                    </div>
                    <div class="information" v-if="detailData.enterpriseName">
                        <p>企业信息</p>
                        <p>{{ detailData.enterpriseName }}</p>
                    </div>
                    <div class="information" v-if="detailData.demandSource == 3 && detailData.appBindPhone">
                        <p>客户APP绑定手机号</p>
                        <p>{{ detailData.appBindPhone }}</p>
                    </div>
                    <div class="information" v-if="detailData.demandSource == 1 && detailData.linkmanDutyName">
                        <p>客户职务</p>
                        <p>{{ detailData.linkmanDutyName }}</p>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="归档信息" force-render
                    v-if="detailData.demandServerTypeName || detailData.firstsClassifyName || detailData.givePointsAmount || detailData.serviceMerchant || detailData.servicePlace">
                    <div class="information" v-if="detailData.demandServerTypeName">
                        <p>需求服务类型</p>
                        <p>{{ detailData.demandServerTypeName }}</p>
                    </div>
                    <div class="information" v-if="detailData.firstsClassifyName">
                        <p>需求一级分类</p>
                        <p>{{ detailData.firstsClassifyName }}</p>
                    </div>
                    <div class="information" v-if="detailData.secondsClassifyName">
                        <p>需求二级分类</p>
                        <p>{{ detailData.secondsClassifyName }}</p>
                    </div>
                    <div class="information" v-if="detailData.thirdsClassifyName">
                        <p>需求三级分类</p>
                        <p>{{ detailData.thirdsClassifyName }}</p>
                    </div>
                    <div class="information" v-if="detailData.isReasonableDemand===0 || detailData.isReasonableDemand===1">
                        <p>是否有效需求</p>
                        <p>{{ detailData.isReasonableDemand == 1 ? "是" : "否" }}</p>
                    </div>
                    <div class="information" v-if="detailData.solutionInformation">
                        <p>实际解决情况</p>
                        <p>{{ solutionInformation(detailData.solutionInformation) }}</p>
                    </div>
                    <div class="information" v-if="detailData.demandSource == 3 && detailData.givePointsType">
                        <p>积分发放类型</p>
                        <p v-if="detailData.givePointsType == 1">安抚积分</p>
                        <p v-if="detailData.givePointsType == 2">奖励积分</p>
                    </div>
                    <div class="information" v-if="detailData.demandSource == 3 && detailData.givePointsAmount">
                        <p>积分发放数量</p>
                        <p>{{ detailData.givePointsAmount }}</p>
                    </div>
                    <div class="information" v-if="detailData.demandSource == 4 && detailData.servicePlace">
                        <p>物业服务地址</p>
                        <p>{{ detailData.servicePlace }}</p>
                    </div>
                    <div class="information" v-if="detailData.demandSource == 4 && detailData.serviceMerchant">
                        <p>商业服务商户</p>
                        <p>{{ detailData.serviceMerchant }}</p>
                    </div>

                    <div class="information" v-if="detailData.unsolvableText">
                        <p>无法解决原因</p>
                        <p>{{ detailData.unsolvableText }}</p>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="工单进程">
                    <a-steps progress-dot direction="vertical">
                        <a-step status="finish" v-for="(item, index) in detailData.lifeCycle" :key="index">
                            <template slot="title">
                                {{ item.lifeCycleName }}
                            </template>
                            <div class="courseBox" slot="description">
                                <div v-if="item.userName">{{ item.lifeCycleId == '20' ? '部门负责人' : '操作人：' }}{{
            item.userName
        }}
                                </div>
                                <div>操作时间：{{ item.gmtModified }}</div>
                                <div class="courseContent" ref="dataList" v-if="shows(item)">
                                    <div v-if="(item.lifeCycleName == '分派工单' && item.principalName) ||
            (item.lifeCycleName == '提交工单' && item.principalName)
            ">
                                        <div v-show="item.principalName">
                                            <div>服务负责人：{{ item.principalName }}</div>
                                        </div>
                                    </div>
                                    <div v-if="item.lifeCycleId == '20'">
                                        {{ item.replyUserText }}
                                    </div>
                                    <div v-if="item.lifeCycleName == '审核通过' && item.replyUserText">
                                        <div class="displayFlex">
                                            回复用户内容：<span class="flex1"
                                                v-html="handleDataEcho(item.replyUserText)"></span>
                                        </div>
                                    </div>

                                    <div v-if="item.lifeCycleName != '提交工单' &&
            item.lifeCycleName != '提交工单' &&
            item.lifeCycleName != '取消回访' &&
            item.lifeCycleName != '分派工单' &&
            item.lifeCycleName != '工单归档' &&
            item.lifeCycleName != '审核通过' &&
            item.lifeCycleName != '选择问卷回访' &&
            item.lifeCycleName != '接收工单'
            ">
                                        <div v-if="item.demandSource == '1'">
                                            <div>
                                                需求解决状态：{{
            item.demandThawedState == '1'
                ? '已解决'
                : item.demandThawedState == '2'
                    ? '无法解决'
                    : item.demandThawedState == '3'
                        ? '延期解决'
                        : '关闭'
        }}
                                            </div>

                                            <div v-show="item.demandThawedState == '1'">服务信息：{{ item.resolvedReason }}
                                            </div>
                                            <div v-show="item.solutionTime">需求解决时间：{{ item.solutionTime }}</div>
                                        </div>

                                        <div v-if="item.demandSource == '2'">
                                            <div v-show="item.serverDescribe">
                                                <div>服务描述：{{ item.serverDescribe }}</div>
                                            </div>
                                            <div v-show="item.unsolvableText">
                                                <div>无法解决原因：{{ item.unsolvableText }}</div>
                                            </div>
                                        </div>

                                        <div class="displayFlex"
                                            v-if="item.serverDescribe && item.lifeCycleName != '结束工单'">
                                            服务信息：<span class="flex1"
                                                v-html="handleDataEcho(item.serverDescribe)"></span>
                                        </div>

                                        <div v-if="item.replyUserText && item.lifeCycleName == '提交服务'">
                                            <div class="displayFlex">
                                                <span>服务描述：</span><span class="flex1"
                                                    v-html="handleDataEcho(item.replyUserText)"></span>
                                            </div>
                                        </div>

                                        <div v-if="item.replyUserText && item.lifeCycleName == '审核驳回'">
                                            <div class="displayFlex">
                                                驳回理由：<span class="flex1"
                                                    v-html="handleDataEcho(item.replyUserText)"></span>
                                            </div>
                                        </div>

                                        <div v-if="item.replyUserText && item.lifeCycleName == '提交审核'">
                                            <div class="displayFlex">
                                                服务描述：<span class="flex1"
                                                    v-html="handleDataEcho(item.replyUserText)"></span>
                                            </div>
                                        </div>

                                        <div v-if="item.replyUserText && item.lifeCycleName == '拒收工单'">
                                            <div class="displayFlex">
                                                拒绝理由：<span class="flex1"
                                                    v-html="handleDataEcho(item.replyUserText)"></span>
                                            </div>
                                        </div>

                                        <div v-if="item.unsolvableText && item.lifeCycleName != '结束工单'">
                                            <div class="displayFlex">
                                                无法解决原因：<span class="flex1"
                                                    v-html="handleDataEcho(item.unsolvableText)"></span>
                                            </div>
                                        </div>
                                        <div v-if="item.fileUrlList">
                                            <div class="displayFlex" v-if="item.fileUrlList.length">
                                                附件上传:

                                                <div class="annexBox flex1">
                                                    <div v-for="(e, i) in item.fileUrlList" class="imgClass" :key="i">
                                                        <el-image fit="cover" class="annexBoxImage" v-if="ifPicture(e)"
                                                            :src="e" :preview-src-list="getimg(item.fileUrlList)">
                                                        </el-image>
                                                        <div v-else class="videobox" @click="videoPlayer(e)">
                                                            <video class="videoClass" :src="e" type="video/mp4"></video>
                                                            <div class="mask">
                                                                <a-icon class="maskPlayer" type="play-circle" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div v-if="item.demandSource == '2' && item.replyUserText">
                                            回复用户内容：{{ item.replyUserText }}
                                        </div>

                                        <div
                                            v-if="item.demandSource == '1' && item.resolvedReason && item.demandThawedState != '1'">
                                            <div>
                                                {{
            item.demandThawedState == '2'
                ? '无法解决原因'
                : item.demandThawedState == '3'
                    ? '延期解决原因'
                    : item.demandThawedState == '4'
                        ? '关闭原因'
                        : ''
        }}：{{ item.resolvedReason }}
                                            </div>
                                        </div>

                                        <div v-if="item.cooperationName">
                                            <div class="displayFlex">服务协作人：<span class="flex1"
                                                    v-html="item.cooperationName"></span></div>
                                        </div>

                                        <div v-if="item.demandThawedState && item.unsolvableText">
                                            <div class="displayFlex">
                                                {{
            item.demandThawedState == '2'
                ? '无法解决原因'
                : item.demandThawedState == '3'
                    ? '延期解决原因'
                    : item.demandThawedState == '4'
                        ? '关闭原因'
                        : ''
        }}：<span class="flex1" v-show="item.demandThawedState == '2' ||
            item.demandThawedState == '3' ||
            item.demandThawedState == '4'
            " v-html="handleDataEcho(item.unsolvableText)"></span>
                                            </div>
                                        </div>

                                        <div v-if="item.isPromptResponse">
                                            <div>
                                                响应是否及时：{{ item.isPromptResponse == 1 ? '是' : item.isPromptResponse == 0
            ?
            '否' : '' }}
                                            </div>
                                        </div>

                                        <div v-if="item.isSatisfaction && item.lifeCycleName == '完成问卷回访'">
                                            用户是否满意：{{
            item.isSatisfaction == 1
                ? '满意'
                : item.isSatisfaction == 2
                                            ? '一般'
                                            : item.isSatisfaction == 3
                                            ? '不满意'
                                            : ''
                                            }}
                                        </div>

                                        <div v-if="item.lifeCycleName == '选择电话回访' || item.lifeCycleName == '完成问卷回访'">
                                            <div v-if="item.isResolved">
                                                需求是否解决: <span>{{item.isResolved==1?'已解决':'未解决'}}</span>
                                            </div>
                                            <div v-if="item.isSatisfaction &&item.lifeCycleName == '选择电话回访'">
                                                客户是否满意: <span>{{ item.isSatisfaction == 1
                                                    ? "满意"
                                                    : item.isSatisfaction == 2
                                                    ? "一般"
                                                    : item.isSatisfaction == 3
                                                    ? "不满意"
                                                    : ""}}</span>
                                            </div>
                                            <div class="displayFlex" v-if="item.returnVisitText">
                                                其他反馈内容：<span class="flex1"
                                                    v-html="handleDataEcho(item.returnVisitText)"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-step>
                    </a-steps>
                </a-tab-pane>
            </a-tabs>
        </a-drawer>

        <a-modal :visible="videoVisible" :footer="null" @cancel="closeVideoPreview">
            <video :src="videoUrl" width="100%" height="600px;" controls="controls" id="video">您的浏览器不支持播放该视频！</video>
        </a-modal>
    </div>
</template>

<script>
import Vue from 'vue';
import { Image } from 'element-ui';
Vue.use(Image);
import { handleData } from '../index';
import { requirementPooldetail } from '@/api/demandPool';
export default {
    data() {
        return {
            visible: true,
            previewImgList: [],
            detailData: {
                fileUrlList: []
            },
            videoVisible: false, //视频播放弹窗
            videoUrl: '', //预览视频地址
            activeKey: ['1', '2', '3', '4', '5'], //折叠面板展开数据
            previewImgList: [],
        };
    },
    mounted() {
        this.getDetail();
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        show(val) {
            console.log(val, '=========================');
            this.visible = val;
        }
    },
    methods: {
        //播放视频
        videoPlayer(url) {
            console.log('============');
            this.videoUrl = url;
            this.videoVisible = true;
        },
        //关闭视频
        closeVideoPreview() {
            this.videoUrl = '';
            this.videoVisible = false;
        },
        //处理进程回显
        handleDataEcho(value) {
            let val = handleData(value);
            return val;
        },
        afterVisibleChange(val) {
            console.log('visible', val);
        },
        onClose() {
            this.$emit('update:show', false)
        },
        //判断是否是图片
        ifPicture(url) {
            console.log(url)
            if (!url) {
                return false
            }
            let type = true;
            let index = url.lastIndexOf('.');
            let result = url.slice(index + 1);
            if (result == 'mp4' || result == 'MOV') {
                type = false;
            }
            return type;
        },
        async getDetail() {
            const res = await requirementPooldetail(this.id);
            if (res.code == 200) {
                //判断是否是图片格式
                res.data.fileUrlList.forEach((element) => {
                    if (this.ifPicture(element)) {
                        this.previewImgList.push(element);
                    }
                });
                this.detailData = res.data;
            } else {
                this.$emit('update:show', false)
                this.$message.error(res.msg);
            }
        },
        getimg(arr) {
            let a = [];
            arr.forEach((element) => {
                if (this.ifPicture(element)) {
                    a.push(element);
                }
            })
            return a
        },
        solutionInformation(val) {
            switch (val) {
                case 1:
                    return '实际解决'
                case 2:
                    return '及时回复、响应'
                case 3:
                    return '受限'
                default:
                    break;
            }
        },
        shows(item) {
            let flag = false;
            if ((item.lifeCycleName == '分派工单' && item.principalName) ||
                (item.lifeCycleName == '提交工单' && item.principalName)) {
                flag = true
            }
            if (item.lifeCycleName == '审核通过' && item.replyUserText) {
                flag = true
            }
            if (item.lifeCycleName != '提交工单' &&
                item.lifeCycleName != '提交工单' &&
                item.lifeCycleName != '取消回访' &&
                item.lifeCycleName != '分派工单' &&
                item.lifeCycleName != '工单归档' &&
                item.lifeCycleName != '审核通过' &&
                item.lifeCycleName != '接收工单') {
                // if (item.replyUserText || item.unsolvableText || item.serverDescribe || item.demandSource == 1 || item.demandSource == 2 || item.fileUrlList) {
                flag = true
                // }
            }

            return flag
        },
        onChange() {
            this.$nextTick(() => {
                let list = this.$refs.dataList;
                console.log(list);
                list.forEach((item) => {
                    if (!item.innerText) {
                        item.style.display = 'none';
                    }
                });
            })
        }
    },
};
</script>

<style scoped lang="scss">
.information {
    &+.information {
        margin-top: 10px;

    }

    p {
        margin-bottom: 0;
        color: #000000D9;
        width: 100%;

        &+p {
            width: 100%;
            color: rgba(0, 0, 0, 0.65);
        }
    }
}

.annexBoxImage {
    width: 100px;
    height: 100px;
}

.courseBox {
    width: 100%;
}

::v-deep .ant-steps-dot .ant-steps-item-content {
    width: calc(100% - 100px);
}

.imgClass {
    width: 100px;
    height: 100px;
    margin-left: 10px;
}

.annexBox {
    display: flex;
}

.imgClass {
    width: 100px;
    height: 100px;
    margin-left: 10px;
}

.annexBoxImage {
    width: 100px;
    height: 100px;
}

.videoClass {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.videobox {
    position: relative;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
}

.mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maskPlayer {
    color: #fff;
    font-size: 25px;
}

.videoPreview {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoPreviewClass {
    width: 50%;
}

.videoPreviewClose {
    position: fixed;
    top: 12%;
    right: 15%;
    color: #fff;
    font-size: 25px;
    cursor: pointer;
}

.courseContent {
    background: #fafafa;
    padding: 7px 5px;
    margin-top: 5px;
}

.courseBox {
    width: 100%;
}

::v-deep .ant-steps-dot .ant-steps-item-content {
    width: calc(100% - 100px);
}

.displayFlex {
    display: flex;
}

.flex1 {
    flex: 1;
}

::v-deep p {
    margin-bottom: 0;
}

.headerDetail {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: 16px;
}
</style>